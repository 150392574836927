import Result from "./Result"

const Results = ({results}) =>
  <div className="results">
  { results.map(
      (result, n) => <Result key={n} result={result}/>
  )}
  </div>

export default Results
