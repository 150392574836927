import API from '../context/API'
import Word from './Dictionary/Word'
import Paging from './Paging';
import { Loading } from '@abw/badger-ui'
import { codify } from '../utils';

const Results = ({API, paging=true}) => {
  if (API.loading) {
    return <Loading/>
  }
  if (API.dictionaryWord) {
    return <Word/>
  }
  if (API.words) {
    return <>
      <h3 className="summary" dangerouslySetInnerHTML={{__html: codify(API.message)}}/>
      <ul className="words">
        { API.words.map(
            word => <li
              key={word}
              className="clickable"
              onClick={() => API.dictionaryLookup(word)}
            >{word}</li>
        )}
      </ul>
      { paging && API.results?.all !== 1
        ? <Paging/>
        : null
      }
    </>
  }
  return null;
}

export default API.Consumer(Results)
