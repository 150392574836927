import { Button } from "@abw/badger-ui";
import API from "../context/API";

const Paging = ({API}) => {
  const results = API.results || { };
  return <div className="paging flex space center">
    { results.less
      ? <Button
          icon="angle-left" color="blue" solid
          onClick={() => API.searchWords({ page_no: results.page_no - 1 })}
        />
      : <Button icon="angle-left" color="grey" disabled/>
    }
    <span>Page {results.page_no} of {results.last_page}</span>
    { results.more
      ? <Button
          icon="angle-right" color="blue" solid
          onClick={() => API.searchWords({ page_no: results.page_no + 1 })}
        />
      : <Button icon="angle-right" color="grey" disabled/>
    }
  </div>
}

export default API.Consumer(Paging)