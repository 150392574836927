import API from '../context/API'
import Meaning from './Meaning';
import Search from './Search';

const Body = ({API}) =>
  API.mode === 'search'
    ? <Search/>
    : <Meaning/>

export default API.Consumer(Body)
