import Definition from "./Definition"
import Synonyms from "./Synonyms"

const Meaning = ({meaning}) =>
  <div className="meaning">
    <div className="part-of-speech">{meaning.category}</div>
    <div className="definitions">
      { meaning.definitions.map(
          (definition, n) => <Definition key={n} definition={definition} n={n+1}/>
      )}
    </div>
    { meaning.synonyms?.length
      ? <Synonyms synonyms={meaning.synonyms}/>
      : null
    }
  </div>

export default Meaning
