import API from '../context/API'
import { useRef, useState } from 'react'
import { Button } from '@abw/badger-ui'
import Results from './Results';

const Search = ({API}) => {
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const submit = e => {
    e.preventDefault();
    if (input.length > 1) {
      API.lookupWords(input)
    }
  }
  const reset = e => {
    e.preventDefault();
    setInput("");
    API.clearLookup();
    inputRef.current.focus();
  }

  return (
    <>
      <form onSubmit={submit}>
        <div className="field">
          <label>Search</label>
          <input
            type="text"
            className="text input larger"
            value={input}
            ref={inputRef}
            autoFocus
            onChange={e => setInput(e.target.value.toUpperCase().replace(/\W/g, '-'))}
          />
        </div>
        { input.length > 1
          ? <div className="flex space mar-t">
              <Button
                text="Reset" color="grey" iconRight="times" solid
                onClick={reset}
              />
              <Button
                text="Search" color="blue" iconRight="search" solid
                onClick={submit}
              />
            </div>
          : null
        }
      </form>
      <div>
        { API.lookup
          ? <Results API={API}/>
          : null
        }
      </div>
    </>
  )
}

export default API.Consumer(Search)
