import API from '../context/API';
import { useState } from 'react'
import { Icon, Radio } from '@abw/badger-ui'
import { ModeOptions } from '../config/Modes';
import Info from './Info';

const Header = ({API}) => {
  const [info, showInfo] = useState(false);

  return <header>
    <div className="flex space end">
      <h1 className="mar-b-none">Wordley</h1>
      <Icon
        name="info-circle"
        className={`info-toggle ${info ? 'active' : ''}`}
        onClick={() => showInfo(! info)}
      />
    </div>
    { info
      ? <Info/>
      : null
    }
    <Radio
      className="small flex space mar-t"
      optionClass="mar-r-none"
      value={API.mode}
      options={ModeOptions}
      onChange={option => API.setMode(option.value)}
    />
  </header>
}

export default API.Consumer(Header)