import React from 'react'
import API from '../utils/API';
import Debug from '../config/Debug';
import Generator from '@abw/react-context-generator'
// import Context from '../utils/Context';
import { bindHandlers, addDebug, addAPIHandlers } from '../utils/Object'
import { inflect } from '@abw/badger-ui';

const State = {
    ready:   true,
    words:   undefined,
    mode:    'search'
};

class APIContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...State };
    this.handlers = bindHandlers(
      this,
      'lookupWords searchWords dictionaryLookup clearLookup clearDictionary setMode lookupMeaning'
    );
    addAPIHandlers(this);
    addDebug(this, ...Debug.context.words);
  }

  //-----------------------------------------------------------------------------
  // Search
  //-----------------------------------------------------------------------------
  lookupWords(lookup, callback) {
    this.searchWords({ lookup });
  }
  searchWords(params, callback) {
    this.debug("searchWord(%o)", params);
    params.lookup = params.lookup || this.state.lookup;
    this.setState({
      loading: true,
      lookup: params.lookup,
      dictionaryWord: undefined,
      dictionaryLoading: false,
      dictionaryResults: undefined,
      dictionaryError: undefined
    });
    this.request(API.words, params)
      .then( data => this.loadedWords(data, callback) );
  }
  loadedWords(data, callback) {
    this.debug("loadedWords: ", data);
    const results = this.fixResults(data.results);
    const words   = results.words;
    this.setState({
        loading: false,
        ready:   true,
        error:   false,
        message: data.message,
        results,
        words
    }, callback);
  }
  fixResults(results) {
    // <sigh> - we get these sent back from the server as strings because Perl like to
    // Do The Right Thing and automagically jiggle things between strings and numbers
    ['all', 'from', 'less', 'limit', 'more', 'offset', 'page_no', 'page_size', 'to', 'total'].forEach(
      item => results[item] = parseInt(results[item])
    )
    return results;
  }

  //-----------------------------------------------------------------------------
  // Meanings
  //-----------------------------------------------------------------------------
  lookupMeaning(lookup, callback) {
    this.searchMeaning({ lookup });
  }
  searchMeaning(params, callback) {
    this.debug("searchMeaning(%o)", params);
    const lookup = params.lookup || this.state.lookup;
    this.setState({
      loading: true,
      lookup: params.lookup,
      dictionaryWord: undefined,
      dictionaryLoading: false,
      dictionaryResults: undefined,
      dictionaryError: undefined
    });
    this.request(API.meaning, { ml: lookup })
      .then( data => this.loadedMeaning(data, callback) );
  }
  loadedMeaning(data, callback) {
    this.debug("loadedMeaning(%o): ", data);
    const words = this.fixMeaning(data).slice(0, 90);
    const message = `Found ${inflect(words.length, 'word')} with the same meaning as \`${this.state.lookup}\``;
    this.debug("words: ", words);
    this.setState({
        loading: false,
        ready:   true,
        error:   false,
        results: undefined,
        message,
        words
    }, callback);
  }
  fixMeaning(data) {
    return data.map(
      entry => entry.word
    )
  }

  dictionaryLookup(word) {
    this.debug("dictionaryLookup(%s)", word);
    this.setState({
      dictionaryWord: word,
      dictionaryLoading: true,
      dictionaryResults: undefined,
      dictionaryError: undefined
    });
    API.dictionary(word)
      .then(
        response => {
          this.debug("dictionary definition: ", response.data);
          this.setState({
            dictionaryLoading: false,
            dictionaryResults: response.data,
          })
        }
      )
      .catch(
        response => {
          this.debug("dictionary lookup error: ", response.message);
          this.setState({
            dictionaryLoading: false,
            dictionaryError: response.message,
          })
        }
      )
  }
  clearLookup() {
    this.setState({
      lookup: undefined,
      words: undefined,
      dictionaryWord: undefined,
      dictionaryLoading: undefined,
      dictionaryDefinition: undefined,
      dictionaryError: undefined,
    })
  }
  clearDictionary() {
    this.setState({
      dictionaryWord: undefined,
      dictionaryLoading: undefined,
      dictionaryDefinition: undefined,
      dictionaryError: undefined,
    })
  }
  setMode(mode) {
    this.clearLookup();
    this.setState({ mode });
  }

  //-----------------------------------------------------------------------------
  // error handling
  //-----------------------------------------------------------------------------
  error(response) {
    this.debug("words context error: ", response);
    this.setState({
      error: response.message,
      saving: false,
      loading: false,
    });
  }

  //-----------------------------------------------------------------------------
  // render
  //-----------------------------------------------------------------------------
  render() {
    let context = {
      ...this.props,
      ...this.state,
      ...this.handlers
    };
    return this.props.render({
      API: context,
    });
  }
}

export default Generator(APIContext);
// export default Context(APIContext);
