import API from '../context/API';
import { Updated, Version } from '../config/Site';
import { Modes } from '../config/Modes';

const Info = ({API}) => {
  const mode = Modes[API.mode];
  return (
    <>
      <div className="flex space end">
        <h2 className="author">By Andy Wardley</h2>
        <div className="version">v{Version} - {Updated}</div>
      </div>
      <p className="about">
        {mode.about}
      </p>
      <p className="help">
        {mode.help}
      </p>
    </>
  )
}

export default API.Consumer(Info)