import './App.scss'
import API from './context/API'
import MyTheme from './components/Theme'
import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'
import { ToastContainer } from 'react-toastify'
import { Theme } from '@abw/badger-ui'

const App = () => <>
  <ToastContainer position="bottom-center" hideProgressBar={false} autoClose={5000} newestOnTop pauseOnHover={false} closeOnClick={true}/>
  <Theme.Provider {...MyTheme}>
    <API.Provider>
      <div className="wordley">
        <Header/>
        <Body/>
        <Footer/>
      </div>
    </API.Provider>
  </Theme.Provider>
</>

export default App
