export function isUndefined(v) {
    return typeof v === 'undefined'
        || v === null;
};

export function isDefined(v) {
    return typeof v !== 'undefined'
        && v !== null;
}

export function areDefined(...args) {
    return args.every( arg => isDefined(arg) );
}

export function isString(value) {
    return typeof value === 'string';
}

export function isArray(value) {
    return Array.isArray(value);
}

export function doNothing() {
  // speak again Cordelia
}

export function splitToList(value) {
    return  isString(value) ? value.split(/,\s*|\s+/)
        :   isArray(value)  ? value
        :   [value];
};

export function debounce(func, timeout=300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(
            () => func.apply(this, args),
            timeout
        );
    };
}

export function sleep(ms) {
  return new Promise(r => setTimeout(r, ms));
}

export function preventDefault(callback, ...args) {
    return callback
      ? function(event) {
          event.preventDefault();
          event.stopPropagation();
          return callback(...args);
        }
      : () => null;
}

export const slashPrefix = uri =>
  uri.match(/^\//)
  ? uri
  : '/' + uri