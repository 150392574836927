import API from "../../context/API";
import { Spinner, Icon } from "@abw/badger-ui";

const Heading = ({API}) => {
  const word    = API.dictionaryWord;
  const loading = API.dictionaryLoading;
  return <h3 className="word">
    <span className="text">{word}</span>
    { loading
        ? <Spinner/>
        : <Icon name="times-circle" className="clear" onClick={API.clearDictionary}/>
    }
  </h3>
}

export default API.Consumer(Heading)
