import Meaning from "./Meaning"

const Result = ({result}) =>
  <div className="result">
    <h3>{result.word}</h3>
    { result.entries?.map(
      (meaning, n) => <Meaning key={n} meaning={meaning}/>
    )}
  </div>

export default Result
