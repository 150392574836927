import axios from 'axios'
import { APIServer } from '../config/Site'
import { toast } from 'react-toastify'

export let APIService = axios.create({
    baseURL: APIServer,
    responseType: "json",
    withCredentials: true
});

export const responseStatusIsOK = response => {
    return response.status >= 200
        && response.status <= 299;
}

export const responseData = response => ({
    ok:       responseStatusIsOK(response) && ! response.data.error,
    response: response,
    status:   response.status,
    data:     response.data,
    message:  response.data.message || response.data.success || response.data.error,
    result:   response.data.status,
});

export const errorData = error => {
    return error.response
        ? responseData(error.response)
        : {
            ok:      false,
            status:  999,
            message: error,
            result:  "error",
        };
}

// default is to display toast messages for both success and error
export const ToastAll = {
    successMessage: true,
    errorMessage:   true,
}

// - this is the quieter option
export const ToastErrors = {
    successMessage: false,
    errorMessage:   true,
};

// - this is the silent option
export const ToastNone = {
    successMessage: false,
    errorMessage:   false,
};

export const responseHandler = (options = ToastAll) => json => {
    if (json.ok) {
        // console.log("OK response: ", json);
        if (json.message && options.successMessage) {
            toast.success(json.message);
        }
    }
    else {
        // console.log("ERROR response: ", json);
        if (json.message && options.errorMessage) {
            toast.error(json.message);
        }
        else {
            console.log("Response error: ", json);
        }
        throw json;
    }
    return json;
}

export const responder = (request, opts) => {
    return request
        .then(responseData)
        .catch(errorData)
        .then(responseHandler(opts));
}

export const GET = (url, defaultOpts) => {
    return (params, opts=defaultOpts) => {
        return responder(
            APIService.get(url, {params}), opts
        );
    }
}
