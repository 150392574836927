import { GET, responder, ToastErrors, ToastNone } from './Network'
import { DictionaryAPI, MeaningAPI } from '../config/Site';
import axios from 'axios';

export const API = {
  test:    GET('/api/test', ToastErrors),
  words:   GET('/api/words', ToastErrors),
  meaning: GET(MeaningAPI, ToastErrors),
  dictionary: word => responder(
    axios.get(DictionaryAPI + word),
    ToastNone
  ),
};

export default API