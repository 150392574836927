import Badger from './Badger.jsx'

const Footer = () => {
  return <footer>
    <Badger/>
    <div className="copyright">
      &copy; Copyright 2022 - 2024 Andy Wardley<br/>
    </div>
  </footer>
}

export default Footer