export const Modes = {
  search: {
    name:  'Letter Search',
    about: 'Search for dictionary words and common phrases.',
    help:  <>Use spaces for missing letters, e.g. <kbd>b dg r</kbd> will match <kbd>badger</kbd></>
  },
  meaning: {
    name:  'Word Meaning',
    about: 'Search for words by meaning.',
    help:  <>Enter a word or phrase to find words with similar meaning</>
  },
}

export const ModeOptions = ['search', 'meaning'].map(
  mode => ({ text: Modes[mode].name,  value: mode }),
)

export default Modes