import API from "../../context/API";
import Error from "./Error";
import Heading from "./Heading";
import Results from "./Results";

const Word = ({API}) => {
  const results  = API.dictionaryResults;
  const loading  = API.dictionaryLoading;
  const error    = API.dictionaryError;

  return <div className={`dictionary ${error ? 'error' : ''}`}>
    <Heading API={API}/>
    { error   ? <Error error={error}/> :
      loading ? null :
      <Results results={results}/>
    }
  </div>
}

export default API.Consumer(Word)
