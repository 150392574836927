import API from "../../context/API";

const Synonyms = ({API, synonyms}) =>
  <div className="synonyms">
    <h4>Synonyms</h4>
    <div className="synonym-results">
    { synonyms.map(
        (synonym, n) => <span
          key={n}
          className="synonym clickable"
          onClick={() => API.dictionaryLookup(synonym)}
        >{synonym}</span>
      ).reduce((prev, curr) => [prev, ', ', curr])
    }
    </div>
  </div>

export default API.Consumer(Synonyms)
